<template>
  <v-card flat>
    <v-card-title>
      Collections
      <v-spacer />
      <v-btn
        color="primary"
        :to="{ name: 'new-collection' }"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        New Collection
      </v-btn>
    </v-card-title>
    <v-card-subtitle>
      Collections hold documents, and are stored in firestore with an id.
    </v-card-subtitle>
    <v-card-text>
      <v-list-item
        v-for="collection in collections"
        :key="collection.id"
        :to="{ name: 'collection', params: { collectionId: collection.id } }"
      >
        <v-list-item-icon>
          <v-icon>
            mdi-book-variant-multiple
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ collection.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            collection('{{ collection.ref }}')
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
    <router-view />
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Collections',
  computed: {
    ...mapState('firebase', ['collections'])
  }
}
</script>
